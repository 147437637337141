*, *:before, *:after {
  margin:0; padding:0; box-sizing:border-box;
}
html {
  font-size:20px;
  scroll-behavior: smooth;
}
body {
  color:rgba(0,0,0,.85);
  /* font-family: 'Source Sans Pro', arial, helvetica, sans-serif; */
  font-family:Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size:1em;
  line-height:1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
section {
  padding:4em 2em;
}
h1,h2,h3,h4,h5,h6 {
  color:#261632;
}
ul, ol {
  counter-reset: my-counter;
  list-style:none;
}
ol {
  margin-top:1em;
}
ol li {
  counter-increment: my-counter;
  font-weight: 600;
  margin-top:1.5em;
  text-transform: uppercase;
}
ol li:before {
  color:tomato;
  content: "0" counter(my-counter);
  font-weight:900;
  font-size:1.25em;
  line-height:1;
  margin-right:.25em;
}
ol li:nth-of-type(n+10):before {
  content: counter(my-counter);
}
h1 {
  font-size: 1em;
  font-weight: 400;
}
#intro h2 {
  font-family: 'Abril Fatface', helvetica, arial, sans-serif;
  font-size: 3.25em;
  letter-spacing: .05em;
  line-height: 1;
  text-transform: initial;
}

h2 {
  font-size:2em;
  font-weight:900;
  margin-bottom:.25em;
  text-transform: uppercase;
}
h3 {
  font-size: 1.5em;
  font-weight:900;
  text-transform: uppercase;
}
button {
  font:inherit;
}
.cta {
  -webkit-appearance: none;
  appearance: none;
  background: tomato;
  border:none;
  box-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  color:#fff;
  cursor: pointer;
  font:inherit;
  margin-top:.5em;
  mix-blend-mode: difference;
  outline:none;
  overflow:hidden;
  padding:1em 2em;
  position:relative;
}
.cta:before {
  background: #d97979;
  content: "";
  height: 100%;
  mix-blend-mode: difference;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-150%);
  width: 100%;
  z-index: -1;
}
.cta:after {
  background:tomato;
  content:"";
  height:100%;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  z-index:-2;
}
.cta:hover {
  box-shadow:0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.cta:hover:before {
  transform: translateX(0);
}
.transition, .accordion, .cta:before, .client, nav a:before, .transition:before {
  transition: all 250ms cubic-bezier(0.420, 0.000, 0.580, 1.000); 
  transition-timing-function: cubic-bezier(0.420, 0.000, 0.580, 1.000);
}
.clearfix:after {
  clear:both;
  content:"";
  display:block;
  height:0;
}